@media (min-width: 1801px) {
    .hands {
        margin-top: 150px;
    }

    .section-heading {
        font-size: 55px;
    }
}

@media (max-width: 1500px) {
    .hands {
        margin-top: 180px;
    }

    .automate-your-life {
        font-size: 200px;
        margin-top: 20px;
    }

    .play-button {
        margin-top: 320px;
    }

    /*.explainer-video-play-button {*/
    /*    margin-top: 320px;*/
    /*}*/

    .banner-video-wrapper {
        height: 75vh;
    }

    .home-page-text {
        top: 790px;
    }

    .switch-buttons-wrapper {
        margin-top: 710px;
    }

    .section-content {
        font-size: 15px;
    }
}

@media (max-width: 1300px) {
    .title {
        margin-left: 25px;
        font-size: 30px;
        letter-spacing: 25px;
    }

    .hands {
        margin-top: 165px;
    }

    .play-button {
        width: 80px;
        height: 80px;
        margin-top: 275px;
    }

    .explainer-video-play-button {
        width: 80px;
        height: 80px;
        /*margin-top: 275px;*/
    }

    .automate-your-life {
        font-size: 200px;
        margin-top: 20px;
    }

    .triangle {
        border-width: 8px 0 8px 14px;
    }

    .automate-your-life {
        font-size: 170px;
        margin-top: 35px;
    }

    .banner-video-wrapper {
        height: 65vh;
    }

    .home-page-text {
        top: 700px;
    }

    .switch-buttons-wrapper {
        margin-top: 620px;
    }

    .page-heading {
        font-size: 120px;
        margin-top: -15px;
        margin-bottom: -10px;
    }

    /*.explainer-video-play-button {*/
    /*    margin-top: 25%;*/
    /*}*/
}

@media (max-width: 1200px) {
    .banner-video-wrapper {
        height: 60vh;
    }

    .home-page-text {
        top: 655px;
    }

    .switch-buttons-wrapper {
        margin-top: 575px;
    }

    .enclosed {
        padding: 3rem;
    }

    .section-heading {
        font-size: 30px;
    }

    .section-subheading {
        font-size: 22px;
    }

    .section-content {
        font-size: 14px;
    }

    .admin-user-subheading {
        font-size: 22px;
    }

    /*.explainer-video-play-button {*/
    /*    margin-top: 25%;*/
    /*}*/
}

@media (max-width: 990px) {
    .title {
        margin-left: 20px;
        font-size: 25px;
        letter-spacing: 20px;
    }

    .hands {
        margin-top: 150px;
    }

    .play-button {
        width: 70px;
        height: 70px;
        margin-top: 235px;
    }

    .explainer-video-play-button {
        width: 70px;
        height: 70px;
        /*margin-top: 235px;*/
    }

    .triangle {
        border-width: 7px 0 7px 12px;
    }

    .automate-your-life {
        font-size: 130px;
        margin-top: 40px;
    }

    .banner-video-wrapper {
        height: 50vh;
    }

    .home-page-text {
        top: 565px;
    }

    .switch-buttons-wrapper {
        margin-top: 485px;
    }

    .enclosed {
        padding: 2rem;
    }

    .page-heading {
        font-size: 80px;
        margin-top: 0;
        margin-bottom: 0;
    }

    .small-heading {
        font-size: 80px;
        line-height: 1;
        margin-top: 18px;
        margin-bottom: 25px;
    }

    .admin-dashboard-entries {
        width: 38%;
    }

    .admin-dashboard-content {
        width: 60%;
    }

    .create-subscription-input {
        width: 85%;
        margin-right: 0;
    }

    .nav-link {
        /*display: none;*/
        opacity: 0;
        visibility: hidden;
    }

    .nav-link-hidden {
        display: none;
    }

    .hamburger {
        /*visibility: visible;*/
        display: block;
    }

    .slide-menu {
        display: block;
    }

    .full-logo-wrapper {
        width: 100%;
        margin: auto auto 30px;
    }

    .full-logo {
        width: 60%;
    }

    .quick-links {
        width: 100%;
        margin: auto;
    }

    .learn-more-wrapper {
        margin-bottom: 1rem;
    }

    .subscription-subheading {
        font-size: 40px;
    }
    .payment-link-subheading {
        font-size: 40px;
    }

    .user-subheading {
        font-size: 40px;
    }

    /*.explainer-video-play-button {*/
    /*    margin-top: 25%;*/
    /*}*/

}

@media (max-width: 767px) {
    .title {
        margin-left: 15px;
        font-size: 20px;
        letter-spacing: 15px;
    }

    .hands {
        margin-top: 130px;
    }

    .play-button {
        width: 60px;
        height: 60px;
        margin-top: 195px;
    }

    .explainer-video-play-button {
        width: 60px;
        height: 60px;
        /*margin-top: 25%;*/
    }

    .triangle {
        border-width: 6px 0 6px 10px;
    }

    .automate-your-life {
        font-size: 90px;
        margin-top: 60px;
    }

    .banner-video-wrapper {
        height: 38vh;
    }

    .home-page-text {
        top: 455px;
    }

    .switch-buttons-wrapper {
        margin-top: 375px;
    }

    .section {
        margin-top: 2rem;
    }

    .enclosed {
        padding: 2rem 1rem;
    }

    .half-section {
        width: 100%
    }

    .section-heading {
        font-size: 22px;
        text-align: center;
    }

    .section-subheading {
        font-size: 18px;
    }

    .section-heading-enclosed {
        margin-top: 1.5rem
    }

    .learn-more-wrapper {
        text-align: center;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .learn-more {
        width: auto;
        padding: 13px 50px;
        font-size: 14px;
    }

    .send-wrapper {
        text-align: center;
        margin-bottom: -2rem;
    }

    .send {
        width: auto;
        padding: 13px 50px;
        font-size: 14px;
    }

    .page-heading {
        font-size: 40px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .mobile-negative-margin-top-2 {
        margin-top: -2rem;
    }

    .mobile-negative-margin-top-1-5 {
        margin-top: -1.5rem;
    }

    .mobile-margin-top-0 {
        margin-top: 0;
    }

    .mobile-margin-bottom-0 {
        margin-bottom: 0;
    }

    .small-heading {
        font-size: 40px;
        line-height: 1;
        margin-top: 18px;
        margin-bottom: 25px;
    }

    .contact-form-form {
        margin-top: -3rem;
    }

    .admin-dashboard-entries {
        width: 100%;
        margin-right: 0;
    }

    .admin-dashboard-content {
        width: 100%;
        text-align: left;
    }

    .admin-subheading {
        margin-top: 1rem;
        margin-bottom: .5rem;
        margin-left: 15px;
    }

    .dashboard-number-of {
        height: max-content;
        font-size: 20px;
        text-align: center;
    }

    .footer-links {
        width: 33%;
    }

    .footer-links-social {
        width: 50%;
        margin-top: 20px;
        margin-bottom: -10px;
    }

    .copyright {
        font-size: 12px;
    }

    .dmca {
        width: 80px;
    }

    .subscription-subheading {
        font-size: 30px;
    }

    .subscription-info {
        width: 80%;
        margin: 20px;
    }

    .subscription-info-heading {
        font-size: 18px;
    }

    .subscription-info-content {
        font-size: 16px;
    }

    .payment-link-subheading {
        font-size: 30px;
    }

    .payment-link-info {
        width: 80%;
        margin: 20px;
    }

    .payment-link-info-heading {
        font-size: 18px;
    }

    .payment-link-info-content {
        font-size: 16px;
    }

    .user-subheading {
        font-size: 30px;
    }

    .user-info {
        width: 80%;
        margin: 20px;
    }

    .user-info-heading {
        font-size: 18px;
    }

    .user-info-content {
        font-size: 16px;
    }

    .admin-user-subheading {
        font-size: 18px;
    }

}

@media (max-width: 550px) {
    .title {
        margin-left: 10px;
        font-size: 15px;
        letter-spacing: 10px;
    }

    .hands {
        margin-top: 110px;
    }

    .play-button {
        width: 50px;
        height: 50px;
        margin-top: 155px;
    }

    .explainer-video-play-button {
        width: 50px;
        height: 50px;
        /*margin-top: 25%;*/
    }

    .triangle {
        border-width: 5px 0 5px 8px;
    }

    .automate-your-life {
        font-size: 60px;
        margin-top: 70px;
    }

    .banner-video-wrapper {
        height: 30vh;
    }

    .home-page-text {
        top: 380px;
        position: absolute;
    }

    .switch-buttons-wrapper {
        margin-top: 300px;
    }

    .page-heading {
        font-size: 35px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .small-heading {
        font-size: 35px;
        line-height: 1;
        margin-top: 18px;
        margin-bottom: 25px;
    }

    .dmca {
        bottom: 35px;
    }

}